document.addEventListener('DOMContentLoaded', () => {
    const carousel = document.querySelector('.testimonial-carousel');
    let slides = null;
    if(carousel) {
        slides = carousel.querySelectorAll('.testimonial-slide');
    } else {
        return;
    }
    let currentIndex = 0;
    const slideIntervalTime = 5000; // 5 seconds
    let slideInterval;

    function showSlide(index) {
        slides.forEach((slide, idx) => {
            slide.style.transform = `translateX(-${index * 100}%)`;
        });
    }

    // Auto slide function
    function startSlideInterval() {
        slideInterval = setInterval(() => {
            currentIndex = (currentIndex + 1) % slides.length;
            showSlide(currentIndex);
        }, slideIntervalTime);
    }

    const nextButton = document.querySelector('#nextButton');
    nextButton.addEventListener('click', () => {
        clearInterval(slideInterval); // clear the interval to avoid overlapping with manual navigation
        currentIndex = (currentIndex + 1) % slides.length;
        showSlide(currentIndex);
        startSlideInterval(); // restart the automatic sliding
    });

    const prevButton = document.querySelector('#prevButton');
    prevButton.addEventListener('click', () => {
        clearInterval(slideInterval);
        currentIndex = (currentIndex - 1 + slides.length) % slides.length;
        showSlide(currentIndex);
        startSlideInterval();
    });

    startSlideInterval();
});