// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require('jquery')
require('page/alerts')
require('page/public_header_dropdown_menu')
require('page/testimonial_carousel')

// require('lazyframe/dist/lazyframe.css')
import lazyframe from "lazyframe";
window.lazyframe = lazyframe;

let jstz = require('jstimezonedetect')


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
// Support component names relative to this directory:
// var ReactRailsUJS = require("react_ujs");
// ReactRailsUJS.useContext(componentRequireContext);



// set time zone
$(document).on('turbolinks:load', () => {
    try {
        let tz = jstz.determine().name()
        let tzField = document.getElementById('initial_application_form_timezone')
        if (tzField) {
            tzField.value = tz
        }

        let tzFieldNewsletter = document.getElementById('email-signup-timezone');
        if (tzFieldNewsletter) {
            tzFieldNewsletter.value = tz;
        }

    } catch {
        // ignore...
    }


});


if (process.env.NODE_ENV === 'production') {
    const Sentry = require('@sentry/browser');
    Sentry.init({dsn: 'https://5d8b76bf6f584766a001b56f49bd4504@sentry.io/1814409'});
    $(document).ready(() => {
        Sentry.configureScope(function (scope) {
            scope.setUser({"email": document.body.getAttribute('data-email')});
        });
    });
}
