const toggleDropdownMenu = (event) => {
    console.log($(event.target))
    if ($(event.target).hasClass('public-hamburger-icon')
            || $(event.target).hasClass('public-header-menu-x') || $(event.target).hasClass('tinted-background')) {
        $('.public-header-dropdown-menu, .tinted-background').toggle();
        $(event.target).attr('aria-expanded', function(_, attr) {
            return attr === 'true' ? 'false' : 'true';
        });

        const expandedMenu = document.querySelector('.public-header-dropdown-menu');
        const homeImage = expandedMenu.querySelector('.header-icon-and-title a');
        if ($('.public-header-dropdown-menu').is(':visible')) {
            // Support screen reader, move the focus to the first item on the menu when the menu is expanded
            homeImage.focus();
        }
    }
};

$(document).on('turbolinks:load', () => {
    $('.public-hamburger-icon, .public-header-menu-x, .tinted-background').on('click', toggleDropdownMenu);
});

$(document).on('turbolinks:before-cache', () => {
    $('.public-hamburger-icon, .public-header-menu-x, .tinted-background').off('click', toggleDropdownMenu);
});
